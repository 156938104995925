<template>
  <!--
    Displays an image taken from the relevant device.
  -->
  <div class="deviceDetailCameraImage text-center">
    <template v-if="loading">
      <LoadingPlaceholder />
    </template>
    <template v-else>
      <img
        v-if="cameraImageAvailable"
        :src="pictureSrc(cameraImageName, cameraImage)"
        class="img-fluid"
      >
      <span v-else>{{ $t('noDataAvailable') }}</span>
    </template>
  </div>
</template>

<script>
export default {
  name: "DeviceDetailCameraImage",
  props: {
    uuid: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      cameraImageAvailable: false,
      cameraImage: null,
      cameraImageName: null,
    }
  },
  created () {
    this.getPhotoDetails();
  },
  methods: {
    pictureSrc (name, image) {
      return 'data:' + name + ';base64,' + image;
    },
    // gets details about photos and screenshots from a device
    getPhotoDetails () {
      this.loading = true;
      // checks wether the images are available
      this.axios.get('/CentralDeviceManagement/GetImageDeviceInfo?deviceUuid=' + this.uuid)
        .then((response) => {
          this.cameraImageAvailable = response.data.cameraImageAvailable;
          if (this.cameraImageAvailable) {
            this.axios.get('/CentralDeviceManagement/GetCameraImage?deviceUuid=' + this.uuid)
              .then((response) => {
                if (response == null) {
                  return;
                }
                if (response.data == null) {
                  return;
                }
                this.cameraImage = response.data.base64Data;
                this.cameraImageName = response.data.fileName;
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
}
</script>
